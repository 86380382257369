<template>
  <form
    class="widget-mini"
    :class="{ 'inline': configs.common.inline, 'separate': configs.button.separate }"
    :style="[
      `background: ${configs.common.backgroundColor}`,
      `width: ${configs.common.width}`,
      `min-width: ${configs.common.minWidth}`,
      `max-width: ${configs.common.maxWidth}`,
      `margin: ${configs.common.center ? '0 auto' : ''}`,
      `border: ${configs.common.border.width} solid ${configs.common.border.color}`,
      `border-radius: ${configs.common.border.radius}`,
      `padding-left: ${configs.common.paddingX}`,
      `padding-right: ${configs.common.paddingX}`,
      `padding-top: ${configs.common.paddingY}`,
      `padding-bottom: ${configs.common.paddingY}`
    ]"
    @submit.prevent="sendForm()">
    <div class="widget-mini-separate">
      <Autocomplete :dev="dev" />
      <Types />
    </div>
    <Button />
  </form>

  <Constructor v-if="settings" />

</template>

<script>
import { mapMutations } from 'vuex'

import Constructor from './components/Constructor'
import Autocomplete from './components/Autocomplete'
import Types from './components/Types'
import Button from './components/Button'

const queryString = require('query-string')

export default {
  data () {
    return {
      dev: false
    }
  },
  components: {
    Constructor,
    Autocomplete,
    Types,
    Button
  },
  watch: {
    configs: {
      handler: function() {
        this.updateVariables()
      },
      deep: true
    }
  },
  created () {
    this.setStatusForm('')
    this.setFormAutocomplete(null)

    const container = document.getElementById('mini-widget-travelmart')
    let options = container.getAttribute('data-options')

    const {
      settings
    } = queryString.parse(location.search)

    if (settings && (settings === 'true' || settings === true)) {
      this.showConstructor(true)
    }

    if (options) {
      try {
        options = JSON.parse(options)

        if (typeof options === 'object') {
          Object.keys(options).forEach((x) => {
            Object.keys(options[x]).forEach((n) => {
              if (typeof options[x][n] === 'object') {
                Object.keys(options[x][n]).forEach((m) => {
                  this.updateConfigs({
                    category: x,
                    option: m,
                    group: n,
                    value: options[x][n][m]
                  })
                })
              } else {
                this.updateConfigs({
                  category: x,
                  option: n,
                  value: options[x][n]
                })
              }
            })
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    this.dev = (location.href).indexOf('mini-widget.dev.vip-zal.ru') !== -1 || (location.href).indexOf('-dev') !== -1 || (location.href).indexOf('dev2') !== -1 || (location.href).indexOf('.dev') !== -1 || (location.href).indexOf(':8080') !== -1

    if (this.dev) {
      this.changeSettings({ common: { url: "/bookonline" } })
      this.changeSettings({ common: { urlEng: "/bookonline_eng" } })
    }

    this.updateVariables()
  },
  computed: {
    settings () { return this.$store.state.settings },
    configs () { return this.$store.state.configs },
    form () { return this.$store.state.form }
  },
  methods: {
    ...mapMutations(['setStatusForm', 'setFormAutocomplete', 'updateConfigs', 'showConstructor', 'changeSettings', 'setValidation']),

    sendForm() {
      if (this.configs.common.url && this.form.terminal && this.form.status !== 'sending') {
        this.setStatusForm('sending')

        const lang = this.configs.common.en ? 'en' : 'ru'
        let url = lang === 'ru' ? this.configs.common.url : this.configs.common.urlEng

        url = (url.startsWith('/') ? window.location.origin : '') + url

        url += url.includes('?') ? '&' : '?'

        const terminalId = this.form.terminal.terminal ? this.form.terminal.terminal.id : this.form.terminal.idTemp

        if (terminalId) {
          url += `terminal_id=${terminalId}`
          if (this.form.type) {
            url += `&flight_type=${this.form.type}`
          }
        } else {
          const iata = this.getIataName(this.form.terminal.iata)
          if (this.form.type) {
            url += `${this.form.type}_iata=${iata}`
          } else {
            url += `iata=${iata}`
          }
        }

        const newUrl = new URL(url)
        const currentUrl = new URL(window.location.href)
        const ref = currentUrl.searchParams.get('ref_partner_id')
        const hardcodeRef = '239fcea1-be39-3cf8-984a-db1318dfd8cd'
        if (ref === hardcodeRef) {
          newUrl.searchParams.append('ref_id', hardcodeRef)
        }
        window.location.href = newUrl.href
      } else {
        if (!this.form.terminal) {
          this.setValidation({
            category: 'autocomplete',
            value: false
          })
        }
      }
    },

    getIataName (iata, full) {
      let response = iata || ''
      if (full) {
        const regExp = /\(([A-Z)]+)\)/gm
        const matches = response.match(regExp)
        if (matches && matches.length > 0) {
          response = matches[matches.length - 1]
        }
      }
      response = response.replace('(', '')
      response = response.replace(')', '')
      response = response.trim()
      return response
    },

    updateVariables () {
      Object.keys(this.configs).forEach((x) => {
        Object.keys(this.configs[x]).forEach((n) => {
          if (this.configs[x][n][0] && n.indexOf('_watch') !== -1) {
            document.documentElement.style.setProperty(`--travelmart-${x}-${n.replace('_watch', '')}`, this.configs[x][n])
          } else {
            Object.keys(this.configs[x][n]).forEach((m) => {
              if (m.indexOf('_watch') !== -1) {
                document.documentElement.style.setProperty(`--travelmart-${x}-${n}-${m.replace('_watch', '')}`, this.configs[x][n][m])
              }
            })
          }
        })
      })
    }
  }
}
</script>

<style lang="sass" scoped>

.widget-mini
  box-sizing: border-box
  outline: none
  font-size: var(--travelmart-common-text-size)
  font-family: var(--travelmart-common-text-family)
  font-style: var(--travelmart-common-text-style)
  font-weight: var(--travelmart-common-text-weight)
  z-index: 999999

  *
    box-sizing: border-box
    outline: none
    font-size: var(--travelmart-common-text-size)
    font-family: var(--travelmart-common-text-family)
    font-style: var(--travelmart-common-text-style)
    font-weight: var(--travelmart-common-text-weight)
    -webkit-appearance: none
    -webkit-tap-highlight-color: transparent

  @media screen and (max-width: 980px)
    width: 100% !important

  &.inline
    display: flex
    align-items: stretch
    justify-content: space-between

    @media screen and (max-width: 980px)
      display: block !important

    .widget-mini-separate
      width: 100%
      display: flex
      align-items: stretch
      justify-content: space-between

      @media screen and (max-width: 980px)
        display: block !important

  &.separate
    flex-direction: column
    align-items: center
    justify-content: center

</style>
