<template>
  <div
    class="widget-mini-types inline"
    :class="{ 'fullWidth': configs.types.fullWidth }"
    :style="[
      `margin-right: ${configs.common.inline ? configs.common.grapX_watch : ''}`,
      `margin-bottom: ${!configs.common.inline ? configs.common.grapY_watch : ''}`
    ]">

    <button
      class="widget-mini-types-item"
      type="button"
      :class="{ 'active': form.type === 'departure' }"
      :style="[
        `height: ${configs.types.height}`,
        `padding-left: ${configs.types.paddingX}`,
        `padding-right: ${configs.types.paddingX}`,
        `padding-top: ${configs.types.paddingY}`,
        `padding-bottom: ${configs.types.paddingY}`,
        `color: ${configs.types.textColor.default_watch}`,
        `border-radius: ${configs.types.border.radius}`,
        `background: ${configs.types.backgroundColor.default}`,
        `border: ${configs.types.border.width_watch} solid ${configs.types.border.default}`
      ]"
      @click="setType('departure')"
      @keydown="changeInputFromKeyboard($event, 'types_1', 'departure')"
      ref="types_1">
      <span
        class="widget-mini-types-item-icon icon-departure"
        v-if="configs.types.icons">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 236.3 219.3" xml:space="preserve"><g><g><g><rect x="6.9" y="202" width="222.1" height="17.4" class="st2"></rect> <path d="M235.7,90.7c-2.5-9.4-12.1-14.9-21.5-12.4L152.1,95L71.4,19.8l-22.6,6.1l48.4,83.9l-58.1,15.5l-23-18.1l-16.9,4.6l21.3,36.9l8.9,15.5l18.8-5l62.1-16.7l50.8-13.6l62.1-16.7C232.6,109.7,238.2,100.1,235.7,90.7z" class="st2"></path></g></g></g></svg>
      </span>
      <template v-if="!configs.common.en">Вылет</template>
      <template v-if="configs.common.en">Departure</template>
    </button>

    <button
      class="widget-mini-types-item"
      type="button"
      :class="{ 'active': form.type === 'arrival' }"
      :style="[
        `height: ${configs.types.height}`,
        `padding-left: ${configs.types.paddingX}`,
        `padding-right: ${configs.types.paddingX}`,
        `padding-top: ${configs.types.paddingY}`,
        `padding-bottom: ${configs.types.paddingY}`,
        `color: ${configs.types.textColor.default_watch}`,
        `border-radius: ${configs.types.border.radius}`,
        `background: ${configs.types.backgroundColor.default}`,
        `border: ${configs.types.border.width_watch} solid ${configs.types.border.default}`
      ]"
      @click="setType('arrival')"
      @keydown="changeInputFromKeyboard($event, 'types_2', 'arrival')"
      ref="types_2">
      <span
        class="widget-mini-types-item-icon icon-arrival"
        v-if="configs.types.icons">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 219.1 219.3" xml:space="preserve"><g><g><g><rect x="1.5" y="202" width="216.8" height="17.4" class="st2"></rect> <path d="M217.5,147c2.3-9.4-3.4-18.9-12.8-21.2l-62.5-15.4L107.9,5.6L85.2,0l1.9,96.8L28.7,82.4L17.3,55.5l-17-4.2l0.8,42.6l0.4,17.9l18.9,4.7l62.5,15.4l51.1,12.6l62.5,15.4C205.7,162.1,215.2,156.4,217.5,147z" class="st2"></path></g></g></g></svg>
      </span>
      <template v-if="!configs.common.en">Прилёт</template>
      <template v-if="configs.common.en">Arrival</template>
    </button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  watch: {
    focus (val) {
      if (val === 'types') {
        this.$refs.types_1.focus()
      }
      if (val === 'types_1') {
        this.$refs.types_1.focus()
      }
      if (val === 'types_2') {
        this.$refs.types_2.focus()
      }
    }
  },

  computed: {
    configs () { return this.$store.state.configs },
    form () { return this.$store.state.form },
    focus () { return this.$store.state.focus }
  },
  methods: {
    ...mapMutations(['setType', 'setFocusElement']),

    changeInputFromKeyboard (e, type, value) {
      if (e && [ 'Enter', 'ArrowDown', 'ArrowUp', 'ArrowRight', 'ArrowLeft', 'Tab' ].includes(e.key)) {
        e.preventDefault()

        if ([ 'Enter' ].includes(e.key)) {
          this.setFocusElement([ 'button', 'next' ])
          this.setType(value)
        }

        if ([ 'Tab' ].includes(e.key)) {
          if (type === 'types_1') {
            this.setFocusElement([ 'types_2', 'next' ])
          } else {
            this.setFocusElement([ 'button', 'next' ])
          }
        }

        if ([ 'ArrowDown', 'ArrowRight' ].includes(e.key)) {
          if (type === 'types_1') {
            this.setFocusElement([ 'types_2', 'next' ])
          } else {
            this.setFocusElement([ 'button', 'next' ])
          }
        }

        if ([ 'ArrowUp', 'ArrowLeft' ].includes(e.key)) {
          if (type === 'types_1') {
            this.setFocusElement([ 'autocomplete', 'prev' ])
          } else {
            this.setFocusElement([ 'types_1', 'prev' ])
          }
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.widget-mini-types
  flex: none
  box-sizing: border-box
  outline: none
  font-size: var(--travelmart-common-text-size)
  font-family: var(--travelmart-common-text-family)
  font-style: var(--travelmart-common-text-style)
  font-weight: var(--travelmart-common-text-weight)

  *
    box-sizing: border-box
    outline: none
    font-size: var(--travelmart-common-text-size)
    font-family: var(--travelmart-common-text-family)
    font-style: var(--travelmart-common-text-style)
    font-weight: var(--travelmart-common-text-weight)

  @media screen and (max-width: 980px)
    margin-bottom: calc(18px + 5px + var(--travelmart-common-grapY))

  &-item
    width: 100%
    cursor: pointer
    overflow: hidden
    position: relative
    display: flex
    align-items: center
    justify-content: center

    &-icon
      flex: none
      margin-right: 6px

      &.icon-departure
        width: 20px

      &.icon-arrival
        width: 20px

      svg
        fill: var(--travelmart-types-textColor-default)

    &:hover,
    &:focus
      color: var(--travelmart-types-textColor-focus) !important
      border-color: var(--travelmart-types-border-focus) !important
      background-color: var(--travelmart-types-backgroundColor-focus) !important

      .widget-mini-types-item-icon svg
        fill: var(--travelmart-types-textColor-focus) !important

    &.active
      color: var(--travelmart-types-textColor-active) !important
      border-color: var(--travelmart-types-border-active) !important
      background-color: var(--travelmart-types-backgroundColor-active) !important

      .widget-mini-types-item-icon svg
        fill: var(--travelmart-types-textColor-active) !important

  &.inline
    display: flex
    align-items: stretch
    justify-content: flex-start
    width: auto !important

    @media screen and (max-width: 980px)
      width: 100% !important

    .widget-mini-types-item
      flex: none
      width: auto
      position: relative
      z-index: 1

      @media screen and (max-width: 980px)
        width: 50% !important

      &:first-child
        border-top-right-radius: 0 !important
        border-bottom-right-radius: 0 !important
        border-right-color: transparent !important
        margin-right: calc(0px - var(--travelmart-types-border-width))

        &:hover,
        &:focus
          &:not(.active)
            z-index: 2
            border-right-color: var(--travelmart-types-border-separate_focus) !important

            & + .widget-mini-types-item
              border-left-color: transparent !important

      &:last-child
        border-top-left-radius: 0 !important
        border-bottom-left-radius: 0 !important
        border-left-color: var(--travelmart-types-border-separate) !important

        &:hover,
        &:focus
          &:not(.active)
            border-left-color: var(--travelmart-types-border-separate_focus) !important

  &.fullWidth
    width: 100% !important

    .widget-mini-types-item
      flex: calc(100% / 2)

</style>
