<template>
  <div
    class="widget-mini-button"
    :style="configs.button.separate ? `width: ${configs.button.width}; margin-top: ${configs.button.marginY}` : ''">
    <button
      class="widget-mini-button-input"
      type="submit"
      :style="[
        `width: ${configs.button.width}`,
        `min-width: ${configs.button.minWidth}`,
        `max-width: ${configs.button.maxWidth}`,
        `height: ${configs.button.height}`,
        `border: ${configs.button.border.width} solid ${configs.button.border.color}`,
        `border-radius: ${configs.button.border.radius}`,
        `padding-left: ${configs.button.paddingX}`,
        `padding-right: ${configs.button.paddingX}`,
        `padding-top: ${configs.button.paddingY}`,
        `padding-bottom: ${configs.button.paddingY}`,
        `color: ${configs.button.text.color}`,
        `cursor: ${configs.button.text.cursor}`
      ]"
      @keydown="changeInputFromKeyboard($event, 'button')"
      ref="button">
        <span
          class="widget-mini-button-input-text"
          :style="[
            `border: ${configs.button.border.width} solid ${configs.button.border.default}`,
            `border-radius: ${configs.button.border.radius}`
          ]">{{ getPlaceholder() }}</span>
        <span
          class="widget-mini-button-input-bgdefault"
          :style="[
            `background: ${configs.button.backgroundColor.default}`,
            `color: ${configs.button.text.default}`,
            `border: ${configs.button.border.width} solid ${configs.button.border.default}`,
            `border-radius: ${configs.button.border.radius}`
          ]">{{ getPlaceholder() }}</span>
        <span
          class="widget-mini-button-input-bgfocus"
          :style="[
            `background: ${configs.button.backgroundColor.focus}`,
            `color: ${configs.button.text.focus}`,
            `border: ${configs.button.border.width} solid ${configs.button.border.focus}`,
            `border-radius: ${configs.button.border.radius}`
          ]">{{ getPlaceholder() }}</span>
        <span
          class="widget-mini-button-input-bgactive"
          :style="[
            `background: ${configs.button.backgroundColor.active}`,
            `color: ${configs.button.text.active}`,
            `border: ${configs.button.border.width} solid ${configs.button.border.active}`,
            `border-radius: ${configs.button.border.radius}`
          ]">{{ getPlaceholder() }}</span>
        <span
          v-if="form.status === 'sending'"
          class="widget-mini-button-input-loading"
          :style="[
            `background: ${configs.button.backgroundColor.active}`,
            `color: ${configs.button.text.active}`,
            `border: ${configs.button.border.width} solid ${configs.button.border.active}`,
            `border-radius: ${configs.button.border.radius}`
          ]"></span>
        </button>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  watch: {
    focus (val) {
      if (val === 'button') {
        if (this.$refs.button) {
          this.$refs.button.focus()
        }
      }
    }
  },

  computed: {
    configs () { return this.$store.state.configs },
    form () { return this.$store.state.form },
    focus () { return this.$store.state.focus }
  },

  methods: {
    ...mapMutations(['setFocusElement']),

    getPlaceholder() {
      let textDefault = 'Заказать'
      let textConstructor = this.configs.button.text.text

      if (textConstructor !== textDefault) {
        return textConstructor
      } else {
        if (this.configs.common.en) {
          return 'Order'
        } else {
          return textDefault
        }
      }
    },

    changeInputFromKeyboard (e) {
      if (e && [ 'ArrowUp', 'ArrowLeft', 'Tab' ].includes(e.key)) {
        e.preventDefault()

        if ([ 'Tab' ].includes(e.key)) {
          this.setFocusElement([ 'button', 'next' ])
        }

        if ([ 'ArrowUp', 'ArrowLeft' ].includes(e.key)) {
          this.setFocusElement([ 'types_2', 'prev' ])
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.widget-mini-button
  flex: none
  box-sizing: border-box
  outline: none
  font-size: var(--travelmart-common-text-size)
  font-family: var(--travelmart-common-text-family)
  font-style: var(--travelmart-common-text-style)
  font-weight: var(--travelmart-common-text-weight)

  *
    box-sizing: border-box
    outline: none
    font-size: var(--travelmart-common-text-size)
    font-family: var(--travelmart-common-text-family)
    font-style: var(--travelmart-common-text-style)
    font-weight: var(--travelmart-common-text-weight)

  @media screen and (max-width: 980px)
    width: 100% !important
    margin-top: 0 !important

  &-input
    overflow: hidden
    position: relative
    background: none
    border: 0

    @media screen and (max-width: 980px)
      width: 100% !important

    &-text
      opacity: 0
      pointer-events: none

    &-bgdefault,
    &-bgfocus,
    &-bgactive,
    &-loading
      display: flex
      align-items: center
      justify-content: center
      z-index: 1
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0

    &-bgfocus,
    &-bgactive
      display: none

    &:hover,
    &:focus
      .widget-mini-button-input-bgdefault
        display: none

      .widget-mini-button-input-bgfocus
        display: flex
        z-index: 2

    &-loading
      z-index: 3

      &::before
        content: ''
        display: block
        height: 20px
        width: 20px
        border-width: 3px
        border-style: solid
        border-color: rgba(255, 255, 255, 1) rgba(255, 255, 255, 1) rgba(255, 255, 255, .25) rgba(255, 255, 255, .25)
        border-radius: 100%
        animation: clockwise .5s linear infinite

    &.active
      .widget-mini-button-input-bgdefault,
      .widget-mini-button-input-bgfocus
        display: none

      .widget-mini-button-input-bgactive
        display: flex
        z-index: 3

@keyframes clockwise
  to
    transform: rotate(360deg) translatez(0)

</style>
