import { createStore } from 'vuex'

export default createStore({
  state: {
    settings: false,
    validations: {
      autocomplete: true,
      type: true
    },
    configs: {
      common: {
        en: false,
        inline: true,
        center: true,
        focus: false,
        url: 'https://vip-zal.ru/bookonline',
        urlEng: 'https://vip-zal.ru/bookonline_eng',
        width: '100%',
        minWidth: '0',
        maxWidth: '100%',
        backgroundColor: 'transparent',
        grapX_watch: '10px',
        grapY_watch: '10px',
        paddingX: '0',
        paddingY: '0',
        border: {
          width: '0px',
          color: 'transparent',
          radius: '3px'
        },
        text: {
          size_watch: '16px',
          family_watch: 'Arial',
          style_watch: 'normal',
          weight_watch: 'normal'
        },
        validation: {
          background_watch: '#FFF4F4',
          borderColor_watch: '#c50000b0'
        }
      },
      autocomplete: {
        width: '100%',
        minWidth: '0',
        maxWidth: '100%',
        height: '50px',
        paddingX_watch: '10px',
        paddingY: '0px',
        background: 'transparent',
        border: {
          width: '1px',
          radius: '3px',
          default: 'rgba(43, 54, 79, .6)',
          focus_watch: '#ffbf25'
        },
        placeholder: {
          text: 'Город, аэропорт или терминал...',
          color_watch: '#dbdce1'
        },
        text: {
          color_watch: '#5d5d5d'
        }
      },
      dropdown: {
        textColor_watch: '#000',
        textHint_watch: '#c0c4cc',
        textQuery_watch: '#0b8ca7',
        background: '#fff',
        border: {
          width: '1px',
          radius: '3px',
          default: 'rgba(43, 54, 79, .6)',
          separate_watch: 'rgba(43, 54, 79, .6)'
        },
        focus: {
          backgroundColor_watch: '#eaeaea'
        },
        active: {
          backgroundColor_watch: '#eaeaea'
        }
      },
      hints: {
        marginTop: '5px',
        color: '#000',
        fontSize: '14px',
        links: {
          default_watch: '#0b8ca7',
          focus_watch: '#ffbf25'
        },
        // array: [
        //   { labelRu: 'Домодедово', labelEn: 'Domodedovo', terminalId: 125 },
        //   { labelRu: 'Санкт-Петербург', labelEn: 'St Petersburg', terminalId: 127 },
        //   { labelRu: 'Сочи', labelEn: 'Sochi', terminalIata: 'AER' },
        //   { labelRu: 'Шереметьево (IATA)', labelEn: 'Sochi', terminalIata: 'SVO' },
        //   { labelRu: 'Шереметьево', labelEn: 'Frankfurt', terminalId: 203 }
        // ]
      },
      types: {
        icons: true,
        fullWidth: false,
        height: '50px',
        paddingX: '30px',
        paddingY: '0',
        border: {
          width_watch: '1px',
          radius: '3px',
          default: 'rgba(44, 62, 80, .2)',
          focus_watch: '#ffbf25',
          active_watch: '#0b8ca7',
          separate_watch: 'rgba(44, 62, 80, .2)',
          separate_focus_watch: '#ffbf25'
        },
        backgroundColor: {
          default: 'transparent',
          focus_watch: 'transparent',
          active_watch: '#0b8ca7'
        },
        textColor: {
          default_watch: '#0b8ca7',
          focus_watch: '#ffbf25',
          active_watch: '#fff'
        }
      },
      button: {
        separate: false,
        width: '100%',
        minWidth: '0',
        maxWidth: '100%',
        height: '50px',
        marginY: '0',
        paddingX: '30px',
        paddingY: '0',
        border: {
          width: '1px',
          radius: '3px',
          default: '#ffbf25',
          focus: '#ffcc51',
          active: '#ffbf25'
        },
        backgroundColor: {
          default: '#ffbf25',
          focus: '#ffcc51',
          active: '#ffbf25'
        },
        text: {
          text: 'Заказать',
          cursor: 'pointer',
          default: '#fff',
          focus: '#fff',
          active: '#fff'
        }
      }
    },
    focus: null,
    form: {
      status: '',
      autocomplete: null,
      type: null,
      dropdown: [],
      terminal: null
    }
  },
  mutations: {
    setType (state, type) {
      state.form.type = type
    },
    setStatusForm (state, type) {
      state.form.status = type
    },
    setFocusElement (state, data) {
      if (data[0] === 'types') {
        if (data[1] === 'next') {
          state.focus = 'types_1'
        } else {
          state.focus = 'types_2'
        }
      } else {
        state.focus = data[0]
      }
    },
    setFormAutocomplete (state, data) {
      state.form.autocomplete = data
    },
    setTerminal (state, data) {
      state.form.terminal = data
      if (!data) return
      let text = ''
      if (data.terminal) {
        text = data.terminal.label
        text = text.replace(data.terminal.city_name, '').trim()
        text = text.replace(data.iata, '')
          .replace('(' + data.terminal.iata + ')', '')
          .replace('()', '')
      } else if (data.idTemp) {
        text = data.labelTemp ? data.labelTemp.replace(data.city, '').trim() : data.airport
        text = text.replace(data.iata, '')
          .replace('()', '')
      } else {
        text = data.airport
      }
      if (text && data.terminal && data.terminal.airport_name && text.indexOf(data.terminal.airport_name) !== -1) {
        if (text.trim() !== data.terminal.airport_name.trim()) {
          text = `${data.terminal.airport_name} ${text}`
        }
      }
      if (!text) {
        if (data.terminal && !data.terminal.id) {
          text = data.terminal.label
        } else {
          text = data.terminal ? data.terminal.city_name : data.city
        }
      }
      state.form.autocomplete = null
      setTimeout(() => {
        state.form.autocomplete = text.trim()
      }, 1)
    },
    updateConfigs (state, data) {
      if (data.group) {
        if (data.group === 'array') {
          if (state.configs[data.category]) {
            if (!state.configs[data.category][data.group]) {
              state.configs[data.category][data.group] = []
            }

            if (state.configs[data.category][data.group]) {
              state.configs[data.category][data.group].push(data.value)
            }
          }
        } else {
          state.configs[data.category][data.group][data.option] = data.value
        }
      } else {
        state.configs[data.category][data.option] = data.value
      }
    },
    showConstructor (state, action) {
      state.settings = action
    },
    changeSettings (state, object) {
      Object.keys(object).forEach((x) => {
        if (typeof object[x] === 'object') {
          Object.keys(object[x]).forEach((n) => {
            if (typeof object[x][n] !== 'object') {
              state.configs[x][n] = object[x][n]
            } else {
              if (n === 'array' && (!object[x][n] || !object[x][n].length)) {
                state.configs[x][n] = object[x][n]
              } else {
                Object.keys(object[x][n]).forEach((m) => {
                  if (typeof object[x][n][m] !== 'object') {
                    state.configs[x][n][m] = object[x][n][m]
                  } else {
                    Object.keys(object[x][n][m]).forEach((p) => {
                      if (typeof object[x][n][m][p] !== 'object') {
                        state.configs[x][n][m] = object[x][n][m]
                      }
                    })
                  }
                })
              }
            }
          })
        } else {
          state.configs[x] = object[x]
        }
      })
    },
    setValidation(state, data) {
      state.validations[data.category] = data.value
    }
  },
  actions: {
  },
  modules: {
  }
})
