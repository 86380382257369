<template>
  <div
    class="widget-mini-hints"
    :class="{ 'hidden': isHiddenHints }"
    :style="[
      `margin-top: ${configs.hints.marginTop}`,
      `color: ${configs.hints.color}`
    ]">
    <div
      class="widget-mini-hints-title"
      :style="[
        `font-size: ${configs.hints.fontSize}`
      ]">
      <template v-if="!configs.common.en">Например:</template>
      <template v-if="configs.common.en">For example:</template>
    </div>

    <div
      v-for="(item, i) in getHintsArray"
      :key="item.terminalId || item.terminalIata"
      class="widget-mini-hints-item"
      :style="[
        `font-size: ${configs.hints.fontSize}`
      ]">
      <span
        v-if="i > 0 && i <= getHintsArray.length - 1"
        class="widget-mini-hints-item-separate">, </span>
      <span
        class="widget-mini-hints-item-city"
        @click="selectHint(item)"
        v-if="!configs.common.en"
        :style="[
          `font-size: ${configs.hints.fontSize}`
        ]">
          <template v-if="item.labelRu">{{ item.labelRu }}</template>
          <template v-else>{{ item.labelEn }}</template>
      </span>
      <span
        class="widget-mini-hints-item-city"
        @click="selectHint(item)"
        v-if="configs.common.en"
        :style="[
          `font-size: ${configs.hints.fontSize}`
        ]">
          <template v-if="item.labelEn">{{ item.labelEn }}</template>
          <template v-else>{{ item.labelRu }}</template>
      </span>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: [ 'dev' ],

  computed: {
    configs () { return this.$store.state.configs },
    form () { return this.$store.state.form },
    isHiddenHints () {
      return !(this.configs.hints && this.configs.hints.array && this.configs.hints.array.length && this.configs.hints.array.find(x => x.terminalId || x.terminalIata))
    },
    getHintsArray () {
      return this.configs.hints.array && this.configs.hints.array.filter(x => (x.terminalId || x.terminalIata) && (x.labelRu || x.labelEn))
    }
  },

  methods: {
    ...mapMutations(['setStatusForm', 'setTerminal', 'setValidation']),

    async selectHint (item) {
      this.setStatusForm('')
      let response = {}

      let id = item.terminalId
      let iata = item.terminalIata

      let url = 'https://dev.vipzal.dev.vip-zal.ru/widgets-api/v3'
      if (!this.dev) url = 'https://vip-zal.ru/widgets-api/v3'

      if (id) {
        url = `${url}/airports?lang=${this.configs.common.en ? 'en' : 'ru'}&terminal_id=${id}`
      } else {
        url = `${url}/airports?lang=${this.configs.common.en ? 'en' : 'ru'}&iata=${iata}`
      }

      const query = await this.axios.get(url)

      if (query && query.data && query.data.id) {
        const terminal = id ? query.data.terminals.find(x => Number(x.id) === Number(id)) : query.data.terminals.find(x => x.iata === iata)

        response = {
          iata: terminal.iata,
          terminal: {
            airport_name: query.data.name,
            city_name: query.data.city ? query.data.city.name : '',
            iata: terminal.iata,
            id: id,
            label: id ? terminal.label : query.data.name,
            service_types: terminal.types
          }
        }

        this.setTerminal(response)

        this.setValidation({
          category: 'autocomplete',
          value: true
        })
      }
    }

  }
}
</script>

<style lang="sass" scoped>

.widget-mini-hints
  display: flex
  align-items: center
  justify-content: flex-start
  flex-wrap: wrap
  box-sizing: border-box
  outline: none
  font-size: var(--travelmart-common-text-size)
  font-family: var(--travelmart-common-text-family)
  font-style: var(--travelmart-common-text-style)
  font-weight: var(--travelmart-common-text-weight)

  *
    box-sizing: border-box
    outline: none
    font-size: var(--travelmart-common-text-size)
    font-family: var(--travelmart-common-text-family)
    font-style: var(--travelmart-common-text-style)
    font-weight: var(--travelmart-common-text-weight)

  &.hidden
    display: none !important

  &-title
    margin-right: 5px

  &-item
    @media screen and (max-width: 980px)
      &:last-child
        display: none

    &-city
      cursor: pointer
      display: inline-block
      line-height: 1
      color: var(--travelmart-hints-links-default)
      border-bottom: 1px dotted var(--travelmart-hints-links-default)

      &:hover
        color: var(--travelmart-hints-links-focus)
        border-bottom: 1px dotted var(--travelmart-hints-links-focus)

</style>
