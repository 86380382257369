<template>
  <div class="widget-mini-constructor">
    <div class="widget-mini-constructor-head">
      <div class="widget-mini-constructor-head-title">Конструктор</div>
    </div>
    <div class="widget-mini-constructor-body">

      <div
        v-for="(config, i) in Object.keys(configs)"
        :key="i"
        class="widget-mini-constructor-body-group">

        <div class="widget-mini-constructor-body-group-title">{{ config }}</div>

        <div
          v-for="(item, n) in Object.keys(configs[config])"
          :key="n"
          class="widget-mini-constructor-body-group-item">
          <div
            v-if="item === 'array' && configs[config][item].length"
            class="widget-mini-constructor-body-group">
            <div class="widget-mini-constructor-body-group-title">{{ item }}</div>
            <div
              v-for="(part, i) in configs[config][item]"
              :key="part"
              class="widget-mini-constructor-body-group-array">
                <div
                  class="widget-mini-constructor-body-group-item-text">
                  <input :value="part.labelRu" @input="configs[config][item][i].labelRu = $event.target.value" class="widget-mini-constructor-body-group-item-input">
                  <div class="widget-mini-constructor-body-group-item-label" :class="{ 'focus': part.labelRu }">labelRu</div>
                </div>

                <div
                  class="widget-mini-constructor-body-group-item-text">
                  <input :value="part.labelEn" @input="configs[config][item][i].labelEn = $event.target.value" class="widget-mini-constructor-body-group-item-input">
                  <div class="widget-mini-constructor-body-group-item-label" :class="{ 'focus': part.labelEn }">labelEn</div>
                </div>

                <div
                  class="widget-mini-constructor-body-group-item-text">
                  <input :value="part.terminalId" @input="configs[config][item][i].terminalId = $event.target.value" class="widget-mini-constructor-body-group-item-input">
                  <div class="widget-mini-constructor-body-group-item-label" :class="{ 'focus': part.terminalId }">terminalId</div>
                </div>

                <div
                  class="widget-mini-constructor-body-group-item-text">
                  <input :value="part.terminalIata" @input="configs[config][item][i].terminalIata = $event.target.value" class="widget-mini-constructor-body-group-item-input">
                  <div class="widget-mini-constructor-body-group-item-label" :class="{ 'focus': part.terminalIata }">terminalIata</div>
                </div>
              </div>
          </div>

          <div
            v-else-if="configs[config][item][0]"
            class="widget-mini-constructor-body-group-item-text">
            <input :value="configs[config][item]" @input="configs[config][item] = $event.target.value" class="widget-mini-constructor-body-group-item-input">
            <div class="widget-mini-constructor-body-group-item-label" :class="{ 'focus': configs[config][item] }">{{ item }}</div>
          </div>

          <div
            v-if="checkShowPicker(item)"
            class="widget-mini-constructor-body-group-item-picker"
            :style="`background-color: ${configs[config][item]}`"></div>

          <div
            v-if="!configs[config][item][0] && typeof configs[config][item] !== 'boolean' && item !== 'array'"
            class="widget-mini-constructor-body-group">
            <div class="widget-mini-constructor-body-group-title">{{ item }}</div>
            <div
              v-for="(inside, n) in Object.keys(configs[config][item])"
              :key="n"
              class="widget-mini-constructor-body-group-item">
              <div class="widget-mini-constructor-body-group-item-text">
                <input :value="configs[config][item][inside]" @input="configs[config][item][inside] = $event.target.value" class="widget-mini-constructor-body-group-item-input">
                <div class="widget-mini-constructor-body-group-item-label" :class="{ 'focus': configs[config][item][inside] }">{{ inside }}</div>
              </div>

              <div
                v-if="checkShowPicker(inside)"
                class="widget-mini-constructor-body-group-item-picker"
                :style="`background-color: ${configs[config][item][inside]}`"></div>
            </div>
          </div>

          <label
            v-if="typeof configs[config][item] === 'boolean'"
            class="widget-mini-constructor-body-group-item-checkbox">
            <input :value="configs[config][item]" @change="configs[config][item] = $event.target.value === 'false' ? true : false" type="checkbox" class="widget-mini-constructor-body-group-item-checkbox-input">
            <div class="widget-mini-constructor-body-group-item-checkbox-text" :class="{ 'focus': configs[config][item] }">{{ item }}</div>
          </label>
        </div>

      </div>

    </div>

    <textarea
      v-model="inputJson"
      placeholder="Вставьте json с настройками..."
      class="widget-mini-constructor-input"></textarea>

    <div class="widget-mini-constructor-json">
      {{ configs }}
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      inputJson: null
    }
  },
  watch: {
    inputJson: {
      handler: function (val) {
        try {
          this.changeSettings(JSON.parse(val))

          this.$nextTick(() => {
            this.updateVariables()
          })
        } catch (error) {
          console.log(error)
        }
      },
      deep: true
    }
  },
  computed: {
    configs () { return this.$store.state.configs }
  },
  methods: {
    ...mapMutations(['changeSettings']),

    checkShowPicker (item) {
      const rules = [
        'background',
        'background_watch',
        'borderColor',
        'borderColor_watch',
        'backgroundColor',
        'backgroundColor_watch',
        'textColor_watch',
        'textHint_watch',
        'textQuery_watch',
        'color',
        'color_watch',
        'default',
        'default_watch',
        'focus',
        'focus_watch',
        'active',
        'active_watch',
        'separate',
        'separate_watch',
        'separate_focus_watch'
      ]

      return rules.includes(item)
    },

    updateVariables () {
      Object.keys(this.configs).forEach((x) => {
        Object.keys(this.configs[x]).forEach((n) => {
          if (this.configs[x][n][0] && n.indexOf('_watch') !== -1) {
            document.documentElement.style.setProperty(`--travelmart-${x}-${n.replace('_watch', '')}`, this.configs[x][n])
          } else {
            Object.keys(this.configs[x][n]).forEach((m) => {
              if (m.indexOf('_watch') !== -1) {
                document.documentElement.style.setProperty(`--travelmart-${x}-${n}-${m.replace('_watch', '')}`, this.configs[x][n][m])
              }
            })
          }
        })
      })
    }
  }
}
</script>

<style lang="sass" scoped>

.widget-mini-constructor
  border-radius: 4px
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px
  margin-top: 30px
  box-sizing: border-box
  outline: none
  font-size: var(--travelmart-common-text-size)
  font-family: var(--travelmart-common-text-family)
  font-style: var(--travelmart-common-text-style)
  font-weight: var(--travelmart-common-text-weight)

  *
    box-sizing: border-box
    outline: none
    font-size: var(--travelmart-common-text-size)
    font-family: var(--travelmart-common-text-family)
    font-style: var(--travelmart-common-text-style)
    font-weight: var(--travelmart-common-text-weight)

  *
    outline: none
    box-sizing: border-box
    font-family: 'Arial', 'Tahoma', sans-serif
    font-size: 14px

  &-head
    display: flex
    align-items: center
    justify-content: flex-start
    height: 48px
    background-color: rgb(80, 95, 121)
    padding: 0 10px
    border-radius: 4px 4px 0 0
    color: #fff

  &-body
    display: flex
    align-items: flex-start
    padding: 8px 10px

    &-group
      padding: 15px 15px 0
      border-radius: 3px
      margin-right: 20px
      background: #ffffff60

      &:last-child
        margin-right: 0

      &-array
        margin-bottom: 15px
        background: rgba(0, 0, 0, .3)
        padding: 5px
        border-radius: 3px

        &:last-child
          margin-bottom: 0

        .widget-mini-constructor-body-group-item-text
          position: relative
          margin-bottom: 5px

          &:last-child
            margin-bottom: 0

      &-title
        color: rgb(23, 43, 77)
        font-weight: bold
        margin-bottom: 15px

      &-item
        position: relative
        margin-bottom: 5px

        &:last-child
          margin-bottom: 0

        &-picker
          width: 20px
          height: 20px
          border-radius: 3px
          position: absolute
          top: calc(50% - 10px)
          right: 10px
          pointer-events: none
          box-shadow: 0px 0px 5px rgba(0, 0, 0, .1)

        .widget-mini-constructor-body-group
          margin: 10px -15px 0
          padding-bottom: 15px
          background: rgba(0, 0, 0, .05)
          border: 0
          border-bottom: 1px solid rgba(0, 0, 0, .1)
          border-radius: 0

          &:last-child
            margin-bottom: 0
            border-bottom: 0

        &-checkbox
          display: flex
          align-items: flex-start
          justify-content: flex-start
          cursor: pointer

          &-input
            flex: none

          &-text
            padding-left: 5px

        &-input
          width: 100%
          padding: 20px 10px 5px

          &:focus
            & + .widget-mini-constructor-body-group-item-label
              font-size: 12px
              top: 5px

        &-label
          position: absolute
          top: calc(50% - 9px)
          left: 10px
          pointer-events: none
          transition: .3s
          opacity: .5

          &.focus
            font-size: 12px
            top: 5px

  &-json
    background-color: rgb(223, 225, 230)
    padding: 8px 10px
    color: rgb(23, 43, 77)
    border-radius: 0 0 4px 4px

  &-input
    width: 100%
    max-height: 50px
    border: 0
    background-color: rgb(223, 225, 230)
    padding: 8px 10px
    margin: 0
    border-radius: 0
    resize: none
    vertical-align: top
    border-bottom: 1px solid rgba(0, 0, 0, .1)

</style>
