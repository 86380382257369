<template>
  <div
    class="widget-mini-autocomplete"
    :style="[
      `width: ${configs.autocomplete.width}`,
      `min-width: ${configs.autocomplete.minWidth}`,
      `max-width: ${configs.autocomplete.maxWidth}`,
      `margin-right: ${configs.common.inline ? configs.common.grapX_watch : ''}`,
      `margin-bottom: ${!configs.common.inline ? configs.common.grapY_watch : ''}`
    ]">

    <div class="widget-mini-autocomplete-input-group">
      <input
        v-model.trim="autocomplete"
        class="widget-mini-autocomplete-input"
        type="text"
        :placeholder="getPlaceholder()"
        :style="[
          `height: ${configs.autocomplete.height}`,
          `border: ${configs.autocomplete.border.width} solid ${configs.autocomplete.border.default}`,
          `border-radius: ${configs.autocomplete.border.radius}`,
          `padding-left: ${configs.autocomplete.paddingX_watch}`,
          `padding-right: ${configs.autocomplete.paddingX_watch}`,
          `padding-top: ${configs.autocomplete.paddingY}`,
          `padding-bottom: ${configs.autocomplete.paddingY}`,
          `background: ${configs.autocomplete.background}`
        ]"
        :class="{ 'error': !validations.autocomplete }"
        ref="autocomplete"
        @focus="showDropdown()"
        @blur="hideDropdown()"
        @input="startSearchTerminal($event)"
        @keydown="changeInputFromKeyboard($event, 'autocomplete')">

      <div
        class="widget-mini-autocomplete-input-group-iata"
        v-if="form.terminal && getIataName(form.terminal.iata).length === 3">{{ getIataName(form.terminal.iata) }}</div>

      <div
        v-if="dropdown.show && dropdown.list.length"
        class="widget-mini-autocomplete-dropdown"
        :style="[
          `border: ${configs.dropdown.border.width} solid ${configs.dropdown.border.default}`,
          `border-radius: ${configs.dropdown.border.radius}`,
          `background-color: ${configs.dropdown.background}`
        ]">
        <div
          class="widget-mini-autocomplete-dropdown-group"
          v-for="(item, i) in dropdown.list"
          :key="i">
          <div
            class="widget-mini-autocomplete-dropdown-group-item item-title"
            v-if="!item.terminal"
            :ref="`dropdown_${i}`"
            :class="{ 'focus': focus === `dropdown_${i}`, 'active': form.terminal && form.terminal.id == item.id }"
            @click="setTerminal(item)">
            <span
              class="airport"
              v-html="getFindedQuery(item.airport)"></span>
            <span
              class="terminal"
              v-if="getTerminalName(item.labelTemp, item.airport, item.city, item.iata) && getTerminalName(item.labelTemp, item.airport, item.city, item.iata) !== item.airport && getTerminalName(item.labelTemp, item.airport, item.city, item.iata) !== item.city"
              v-html="getFindedQuery(getTerminalName(item.labelTemp, item.airport, item.city, item.iata))"></span>
            <span
              class="city"
              v-if="getFindedQuery(item.airport) !== getFindedQuery(item.city)"
              v-html="getFindedQuery(item.city)"></span>
            <span
              class="iata"
              v-html="getFindedQuery(getIataName(item.iata))"></span>
          </div>

          <div
            class="widget-mini-autocomplete-dropdown-group-item"
            v-if="item.terminal && getTerminalName(item.terminal.label, item.airport, item.city, item.iata, true)"
            :ref="`dropdown_${i}`"
            :class="{ 'focus': focus === `dropdown_${i}`, 'active': form.terminal && form.terminal.id == item.id }"
            @click="setTerminal(item)">
            <span v-html="getFindedQuery(getTerminalName(item.terminal.label, item.airport, item.city, item.iata, true))"></span>
          </div>
        </div>
      </div>
    </div>

    <Hints :dev="dev" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import debounce from 'lodash/debounce'

import Hints from './Hints'

export default {
  props: [ 'dev' ],

  data () {
    return {
      autocomplete: null,
      dropdown: {
        show: false,
        list: []
      }
    }
  },

  components: {
    Hints
  },

  mounted () {
    this.autocomplete = null
    if (this.configs.common.focus && this.$refs.autocomplete) {
      this.$refs.autocomplete.focus()
      this.$nextTick(() => this.$refs.autocomplete.blur())
    }
  },

  watch: {
    focus (val) {
      if (val === 'autocomplete') {
        this.$refs.autocomplete.focus()
      }
    },
    'form.autocomplete' (val) {
      this.autocomplete = val
    },
    'form.terminal' (val) {
      if (!val || !val.city) {
        this.dropdown.list = []
      }
    }
  },

  computed: {
    configs () { return this.$store.state.configs },
    form () { return this.$store.state.form },
    focus () { return this.$store.state.focus },
    validations () { return this.$store.state.validations }
  },

  methods: {
    ...mapMutations(['setStatusForm', 'setFocusElement', 'setTerminal', 'setValidation']),

    selectItem(i) {
      if (this.dropdown && this.dropdown.list && this.dropdown.list.length) {
        this.setTerminal(this.dropdown.list[i])
        this.hideDropdown()
        this.setValidation({
          category: 'autocomplete',
          value: true
        })
      }
    },

    getPlaceholder() {
      let textDefault = 'Город, аэропорт или терминал...'
      let textConstructor = this.configs.autocomplete.placeholder.text

      if (textConstructor !== textDefault) {
        return textConstructor
      } else {
        if (this.configs.common.en) {
          return 'City, airport or terminal...'
        } else {
          return textDefault
        }
      }
    },

    startSearchTerminal: debounce(function (e) {
      if (e) this.autocomplete = e.target.value
      this.setStatusForm('')
      this.setValidation({
        category: 'autocomplete',
        value: true
      })

      if (this.autocomplete && this.autocomplete.length >= 2) {
        let url = 'https://dev.vipzal.dev.vip-zal.ru/widgets-api/v3'
        if (!this.dev) url = 'https://vip-zal.ru/widgets-api/v3'

        let autocomplete = this.autocomplete.replace(/[^a-zA-Zа-яА-ЯеЕёЁ -]/g, '')

        if (autocomplete) {
          this.axios.get(`${url}/terminals-autocomplete?lang=${this.configs.common.en ? 'en' : 'ru'}&query=${autocomplete}`).then((response) => {
            this.dropdown.list = this.parseTerminals(response.data)[0]
          })
        }
      } else {
        this.setTerminal(null)
        this.dropdown.list = []
      }
    }, 500),

    getFindedQuery (text) {
      let response = text
      let autocomplete = this.autocomplete
      autocomplete = autocomplete.replace(/[^a-zA-Zа-яА-ЯеЕёЁ -]/g, '')

      const regExp = new RegExp(autocomplete, 'gi')
      response = response.replace(regExp, function (str) {
        return '<span class="finded" style="color: var(--travelmart-dropdown-textQuery) !important">' + str + '</span>'
      })
      return response
    },

    getIataName (iata, full) {
      let response = iata || ''
      if (full) {
        const regExp = /\(([A-Z)]+)\)/gm
        const matches = response.match(regExp)
        if (matches && matches.length > 0) {
          response = matches[matches.length - 1]
        }
      }
      response = response.replace('(', '')
      response = response.replace(')', '')
      response = response.trim()
      return response
    },

    getTerminalName (label, airport, city, iata, isNull) {
      let response = label || ''
      if (city) {
        response = response.replace(city, '')
      }
      if (iata) {
        response = response.replace(iata, '')
      }
      response = response.trim()
      let array = response.split(' ')
      if (array.length > 1) {
        response = response.replace(airport, '')
        response = response.trim()
      }
      if (isNull) {
        if (response.trim().length <= 0) {
          response = label
          if (iata) {
            response = response.replace(iata, '')
            response = response.trim()
          }
        }
      }
      return response
    },

    parseTerminals (json) {
      let array = []
      let response = []
      let pretty = []

      json.forEach((item) => {
        const regExp = /\(([A-Z)]+)\)/gm
        const matches = item.label.match(regExp)
        const part = {
          iata: matches ? matches[matches.length - 1] : `(${item.iata})`,
          city: item.city_name,
          airport: item.airport_name,
          description: item.description,
          terminals: [item]
        }
        if (!array.length) {
          array.push(part)
        } else {
          let finded = false
          array.forEach((iata, i) => {
            if ((matches && iata.iata === matches[matches.length - 1]) || (iata.iata === `(${item.iata})`)) {
              finded = i
            }
          })
          if (finded === false) {
            array.push(part)
          } else {
            array[finded].terminals.push(item)
          }
        }
      })
      array.forEach((item) => {
        // Если в аэропорте 1 терминал, не показываем его
        if (item.terminals.length > 1 || json.length === 1) {

          // Добавляем к выводу аэропорт
          response.push({
            city: item.city,
            iata: item.iata,
            airport: item.airport,
            description: item.description
          })

          item.terminals.forEach((terminal) => {
            const terminalTemp = terminal
            terminalTemp.name = terminal.label.replace(terminal.city_name, '').trim()
            response.push({
              city: item.city,
              iata: item.iata,
              airport: item.airport,
              description: item.description,
              terminal: terminalTemp
            })
          })
        } else {

          // Добавляем к выводу аэропорт
          response.push({
            idTemp: item.terminals[0].id,
            city: item.city,
            iata: item.iata,
            airport: item.airport,
            description: item.description,
            labelTemp: item.terminals[0].label
          })
        }
      })

      //  Формируем красивый массив
      json.forEach((item) => {
        let finded = false
        if (pretty.length > 0) {
          pretty.forEach((x, i) => {
            if (x.iata === item.iata) {
              finded = i
            }
          })
        }
        if (finded === false) {
          const part = {
            iata: item.iata,
            city: item.city_name,
            airport: item.airport_name,
            description: item.description,
            terminals: [item]
          }
          pretty.push(part)
        } else {
          pretty[finded].terminals.push(item)
        }
      })
      return [ response, pretty ]
    },

    showDropdown () {
      this.setFocusElement([ 'autocomplete', 'next' ])

      if (this.autocomplete && !this.dropdown.list.length) {
        this.startSearchTerminal()
      }

      this.dropdown.show = true
    },

    hideDropdown () {
      setTimeout(() => {
        this.dropdown.show = false
        this.setFocusElement([ null ])
      }, 200)
    },

    changeInputFromKeyboard (e) {
      if (e && [ 'Enter', 'ArrowDown', 'ArrowUp', 'Tab' ].includes(e.key)) {
        e.preventDefault()

        if (this.focus === 'autocomplete') {

          if ([ 'Enter', 'Tab' ].includes(e.key)) {
            this.selectItem(0)
            this.setFocusElement([ 'types', 'next' ])
          }

          if ([ 'ArrowDown' ].includes(e.key)) {
            this.setFocusElement([ 'dropdown_0', 'next' ])
          }

          if ([ 'ArrowUp' ].includes(e.key)) {
            this.setFocusElement([ 'types', 'prev' ])
          }
        } else if (this.focus && this.focus.indexOf('dropdown_') !== -1) {
          let number = this.focus.replace('dropdown_', '') * 1

          if ([ 'Enter' ].includes(e.key)) {
            this.selectItem(this.focus.split('dropdown_')[1])
            this.setFocusElement([ 'types', 'next' ])
            this.setTerminal(this.dropdown.list[number])
          }

          if ([ 'Tab' ].includes(e.key)) {
            this.setFocusElement([ 'types', 'next' ])
          }

          if ([ 'ArrowDown' ].includes(e.key)) {
            if (this.dropdown.list.length > number + 1) {
              this.setFocusElement([ `dropdown_${number + 1}`, 'next' ])
            } else {
              this.setFocusElement([ 'dropdown_0', 'next' ])
            }
          }

          if ([ 'ArrowUp' ].includes(e.key)) {
            if (number - 1 >= 0) {
              this.setFocusElement([ `dropdown_${number - 1}`, 'prev' ])
            } else {
              this.setFocusElement([ 'autocomplete', 'prev' ])
            }
          }
        }
      } else {
        this.startSearchTerminal(e)
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.widget-mini-autocomplete
  flex: 1
  position: relative

  box-sizing: border-box
  outline: none
  font-size: var(--travelmart-common-text-size)
  font-family: var(--travelmart-common-text-family)
  font-style: var(--travelmart-common-text-style)
  font-weight: var(--travelmart-common-text-weight)

  *
    box-sizing: border-box
    outline: none
    font-size: var(--travelmart-common-text-size)
    font-family: var(--travelmart-common-text-family)
    font-style: var(--travelmart-common-text-style)
    font-weight: var(--travelmart-common-text-weight)

  @media screen and (max-width: 980px)
    width: 100% !important
    flex: none !important
    margin-bottom: var(--travelmart-common-grapY)

  &-input
    width: 100%
    padding-right: 50px !important
    color: var(--travelmart-autocomplete-text-color)

    &::-webkit-input-placeholder
      color: var(--travelmart-autocomplete-placeholder-color)

    &::-moz-placeholder
      color: var(--travelmart-autocomplete-placeholder-color)

    &:-moz-placeholder
      color: var(--travelmart-autocomplete-placeholder-color)

    &:-ms-input-placeholder
      color: var(--travelmart-autocomplete-placeholder-color)

    &:focus
      border-color: var(--travelmart-autocomplete-border-focus) !important

      & + .widget-mini-autocomplete-input-group-iata
        display: none

    &-group
      position: relative

      &-iata
        position: absolute
        top: 0
        right: 10px
        bottom: 0
        pointer-events: none
        display: flex
        align-items: center
        justify-content: center
        color: var(--travelmart-autocomplete-textColor)

    &.error
      background-color: var(--travelmart-common-validation-background) !important
      border-color: var(--travelmart-common-validation-borderColor) !important

  &-dropdown
    max-height: 240px
    overflow-y: auto
    padding: 5px 0
    position: absolute
    top: calc(100% + 2px)
    left: 0
    right: 0
    background: #fff
    z-index: 2

    &-group
      border-bottom: 1px solid var(--travelmart-dropdown-border-separate)

      &:last-child
        border-bottom: 0

      &-item
        height: 37px
        display: flex
        align-items: center
        justify-content: flex-start
        padding-left: calc(var(--travelmart-autocomplete-paddingX) + 20px)
        padding-right: 10px
        border-bottom: 1px solid var(--travelmart-dropdown-border-separate)
        cursor: pointer
        color: var(--travelmart-dropdown-textColor)

        &:last-child
          border-bottom: 0

        &.item-title
          padding-left: var(--travelmart-autocomplete-paddingX)

          .airport
            font-weight: bold

          .terminal
            margin-left: 5px

          .city
            margin-left: 5px
            color: var(--travelmart-dropdown-textHint)
            font-weight: normal

          .iata
            margin-left: auto
            font-weight: normal

        &:hover,
        &.focus
          background-color: var(--travelmart-dropdown-focus-backgroundColor)

</style>
